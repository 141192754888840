/* stylelint-disable prettier/prettier */
@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}/* Spacing */
/* Colors */
.zola-ui {
  /* Headers */
  /* Body */
  /* Page Sections */
}
.zola-ui .pre-auth__h1 {
  color: var(--text-primary, #21201f);
  font-weight: 700;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 52px;
  line-height: 1.2;
  letter-spacing: 1px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__h1 {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.2px;
  }
}
.zola-ui .pre-auth__h2 {
  color: var(--text-primary, #21201f);
  margin-bottom: 10px;
  font-weight: 700;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.2px;
}
.zola-ui .pre-auth__h3 {
  color: var(--text-primary, #21201f);
  margin-bottom: 10px;
  font-weight: 700;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0;
}
.zola-ui .pre-auth__h4 {
  color: var(--text-primary, #21201f);
  margin-bottom: 10px;
  font-weight: 700;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.zola-ui .pre-auth__h5 {
  color: var(--text-primary, #21201f);
  margin-bottom: 10px;
  font-weight: 700;
  font-family: 'circular', helvetica, sans-serif;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}
.zola-ui .pre-auth__script-header {
  color: var(--text-primary, #21201f);
  font-family: 'Sakura Regular', 'Georgia', serif;
  font-size: 72px;
  font-weight: 400;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .zola-ui .pre-auth__script-header {
    font-size: 45px;
  }
}
.zola-ui .pre-auth__primary-body-copy {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: var(--text-primary, #21201f);
  font-family: 'circular', helvetica, sans-serif;
}
.zola-ui .pre-auth__secondary-body-copy {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: var(--text-secondary, #505050);
  font-family: 'circular', helvetica, sans-serif;
}
.zola-ui .pre-auth__tertiary-body-copy {
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: var(--text-secondary, #505050);
  font-family: 'circular', helvetica, sans-serif;
}
.zola-ui .pre-auth__primary-link {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 1.2px;
  cursor: pointer;
  color: var(--text-primary, #0e0e0e);
}
.zola-ui .pre-auth__primary-link .icon-link {
  font-size: 8px;
  position: relative;
  top: -1px;
  left: -1px;
  padding-left: 4px;
  -webkit-text-stroke: 1px var(--text-primary, #0e0e0e);
}
.zola-ui .pre-auth__primary-link:hover {
  color: var(--text-secondary, #4a4a4a);
}
.zola-ui .pre-auth__primary-link:hover .icon-link {
  border-bottom: 1px solid var(--text-secondary, #4a4a4a);
  -webkit-text-stroke: 1px var(--text-secondary, #4a4a4a);
}
.zola-ui .pre-auth__bg--periwinkle {
  background-color: #e5eef7;
}
.zola-ui .pre-auth__bg--tan {
  background-color: #f4efe6;
}
.zola-ui .pre-auth__bg--blue {
  background-color: #336081;
}
.zola-ui .pre-auth__bg--blue a,
.zola-ui .pre-auth__bg--blue p {
  color: #ffffff;
}
.zola-ui .pre-auth__bg--blue a:hover {
  color: #ffffff;
}
.zola-ui .pre-auth__bg--blue .pre-auth__h2,
.zola-ui .pre-auth__bg--blue .pre-auth__h3,
.zola-ui .pre-auth__bg--blue .pre-auth__h4,
.zola-ui .pre-auth__bg--blue .pre-auth__h5,
.zola-ui .pre-auth__bg--blue .pre-auth__primary-body-copy,
.zola-ui .pre-auth__bg--blue .pre-auth__secondary-body-copy {
  color: #ffffff;
}
.zola-ui .pre-auth__center-text {
  text-align: center;
}
.zola-ui .pre-auth__page-section {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__page-section {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
.zola-ui .pre-auth__page-section--compressed {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__page-section--compressed {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
.zola-ui .pre-auth__primary-spacing--above {
  margin-top: 40px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__primary-spacing--above {
    margin-top: 30px;
  }
}
.zola-ui .pre-auth__secondary-spacing--above {
  margin-top: 30px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__secondary-spacing--above {
    margin-top: 20px;
  }
}
.zola-ui .pre-auth__tertiary-spacing--above {
  margin-top: 20px;
}
@media (max-width: 992px) {
  .zola-ui .pre-auth__tertiary-spacing--above {
    margin-top: 10px;
  }
}
.zola-ui .button.pre-auth.pre-auth__button {
  display: inline-block;
}
@media (max-width: 767px) {
  .zola-ui .button.pre-auth.pre-auth__button {
    display: block;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}
.zola-ui .button.pre-auth.pre-auth__button:hover {
  text-decoration: none;
}
.zola-ui a.v2-button {
  padding-left: 16px;
  padding-right: 16px;
}
.email-capture-component {
  background-color: #f5f7fa;
  padding: 12px 20px 24px;
  border-top: 1px solid #d9d9d9;
}
@media (min-width: 768px) {
  .email-capture-component {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .email-capture-component {
    padding: 20px 40px;
  }
}
@media (min-width: 992px) {
  .email-capture-component {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .email-capture-component .email-capture__image--couch {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .email-capture-component .email-capture__image--couch {
    display: none;
  }
}
@media (min-width: 992px) {
  .email-capture-component .email-capture__image--couch {
    display: flex;
    align-self: center;
    width: 134px;
    height: 60px;
    margin-right: 15px;
  }
}
.email-capture-component h3 {
  font-family: 'new-spirit', 'helvetica-bold', serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
@media (min-width: 992px) {
  .email-capture-component .email-content--non-desktop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .email-capture-component .email-content--non-desktop .email-capture__fine-print-span {
    display: block;
  }
}
.email-capture-component .email-content--non-desktop .email-capture__input-row {
  display: flex;
}
.email-capture-component .email-content--non-desktop .email-capture__button--submit {
  margin-top: 4px;
  margin-left: 12px;
}
.email-capture-component .email-content--non-desktop .email-capture__input {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .email-capture-component .email-content--non-desktop .email-capture__input {
    width: 100%;
  }
}
.email-capture-component .email-content--non-desktop .email-capture__input .error-message {
  position: absolute;
  left: 0;
  bottom: -20px;
}
.email-capture-component .email-content--non-desktop .email-capture__offer-details {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  margin-bottom: 24px;
}
.email-capture-component .email-content--non-desktop .email-content__success-message {
  display: flex;
}
.email-capture-component .email-content--non-desktop .email-content__success-message p {
  font-weight: 400;
  font-size: 16px;
  font-family: 'circular', serif;
  line-height: 22px;
}
.email-capture-component .email-content--non-desktop .zolaicon-checkmark {
  margin-top: 1px;
  margin-right: 8px;
  color: #005759;
  background-color: #d7f5d9;
  border-radius: 50%;
  font-size: 13px;
  width: 20px;
  height: 20px;
  padding: 2px;
}
@media (max-width: 767px) {
  .email-capture-component .email-content--desktop {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .email-capture-component .email-content--desktop {
    display: none;
  }
}
@media (min-width: 992px) {
  .email-capture-component .email-content--desktop {
    display: flex;
  }
  .email-capture-component .email-content--desktop h3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .email-capture-component .email-content--desktop .email-capture__offer-details-row {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
  .email-capture-component .email-content--desktop .email-capture__offer-details {
    font-size: 12px;
    margin-bottom: 0;
  }
  .email-capture-component .email-content--desktop .email-capture__input-row {
    display: flex;
    margin-left: 36px;
  }
  .email-capture-component .email-content--desktop .email-capture__button--submit {
    margin-top: 4px;
    margin-left: 12px;
  }
  .email-capture-component .email-content--desktop .email-capture__input {
    min-width: 277px;
  }
  .email-capture-component .email-content--desktop .email-capture__input .error-message {
    position: absolute;
    left: 0;
    bottom: -20px;
  }
  .email-capture-component .email-content--desktop .zolaicon-checkmark {
    margin-top: 1px;
    margin-right: 8px;
    color: #005759;
    background-color: #d7f5d9;
    border-radius: 50%;
    font-size: 13px;
    width: 20px;
    height: 20px;
    padding: 2px;
  }
  .email-capture-component .email-content--desktop .email-content__success-message {
    display: flex;
    max-width: 325px;
    margin-left: 60px;
    margin-top: 4px;
  }
}
